import React, { FC, memo, useCallback, useMemo } from 'react';
import styles from './MintingForm.module.scss';
import Countdown from '~/components/Countdown/Countdown';
import ConnectButton from '~/components/ConnectButton/ConnectButton';
import Dashboard from '~/components/Dashboard/Dashboard';
import { useTypedSelector } from '~/hooks/useTypedSelector';
import Caver from 'caver-js';
import Guide from "~/components/Guide/Guide";
import { getSignedToolkit } from "~/cores/getSignedToolkit";
import { CONTRACT_ADDRESS } from "~/cores/variables";
import { toast } from "react-toastify";

export interface MintingFormProps {}

const categories = [1, 3, 5, 10];

const MintingForm: FC<MintingFormProps> = memo(() => {
  const { price, address, status } = useTypedSelector((state) => ({
    status: state.kaikas.status,
    address: state.kaikas.address,
    price: state.utils.price,
  }));

  const tryMinting = useCallback((count: number) => {
    if (!address || !price) {
      return;
    }

    const toolkit = getSignedToolkit();
    if (toolkit === null) {
      return
    }

    const [caver, contract] = toolkit;

    caver.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CONTRACT_ADDRESS,
        data: contract.methods.mint(count).encodeABI(),
        value: caver.utils.toHex(caver.utils.toBN(price).mul(caver.utils.toBN(count))),
        gas: 1000000 * count,
      })
      .on('transactionHash', function (hash) {})
      .on('receipt', function (receipt) {
        toast.success('😎 민팅에 성공하였습니다.', { position: 'bottom-right' });
      })
      .on('error', (e) => {
        if (e.message.indexOf('User denied') !== -1) {
          toast.error('민팅이 취소되었습니다.', { position: 'bottom-right' })
        } else {
          toast.error('😱 민팅에 실패하였습니다.', { position: 'bottom-right' })
        }
      });
  }, [price, address]);

  const renderedMintButtons = useMemo(() => {
    return categories.map((it) => {
      const priceNode =
        price !== null ? (
          <span className={styles.price}>
            ({Caver.utils.fromPeb(Caver.utils.toBN(price).mul(Caver.utils.toBN(it)).toString())}KLAY)
          </span>
        ) : null;

      return (
        <button type="button" className={styles.mintButton} key={it} onClick={() => {
          tryMinting(it)
        }}>
          <span className={styles.count}>{it}개</span>
          {priceNode}
        </button>
      );
    });
  }, [price, tryMinting]);

  return (
    <div className={styles.mintingForm}>
      <div className={styles.inner}>
        {/*<Countdown />*/}
        <div className={styles.form}>
          <h1>민팅이 마감되었습니다.</h1>
        {/*  <Dashboard />*/}
        {/*  {status === 'connected' ? (*/}
        {/*    <div>*/}
        {/*      {renderedMintButtons}*/}
        {/*    </div>*/}
        {/*  ) : (*/}
        {/*    <ConnectButton />*/}
        {/*  )}*/}
        </div>
      </div>
      <Guide />
    </div>
  );
});

export default MintingForm;
