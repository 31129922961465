import React, { FC, memo, useEffect } from 'react';
import styles from './App.module.scss';
import NewHeader from "~/components/NewHeader/NewHeader";
import Footer from "~/components/Footer/Footer";
import MintingForm from "~/components/MintingForm/MintingForm";
import { useDispatch } from "react-redux";
import { KaikasActions } from "~/reducers/kaikas";

export interface AppProps {}

const App: FC<AppProps> = memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.klaytn?.selectedAddress) {
      dispatch(KaikasActions.tryConnect());
    }
  }, [dispatch]);

  return (
    <div className={styles.app}>
      <NewHeader />
      <MintingForm />
      <Footer />
    </div>
  );
});

export default App;
