import React, { FC, memo } from 'react';
import styles from './Guide.module.scss';

export interface GuideProps {

}

const Guide: FC<GuideProps> = memo(() => {
  return (
    <div className={styles.guide}>
      <ul className={styles.items}>
        <li>프리 민팅은 봇을 방지하기 위해 개발된 새로운 컨트랙트에서 민팅하는 것을 의미합니다.</li>
        <li>해당 컨트랙트에서 민팅을 하더라도 즉시 랜드 NFT가 지갑으로 전송되지 않습니다.<br /><u>약 12시간 내에 랜드NFT가 민팅에 성공한 지갑으로 에어드랍됩니다.</u></li>
        <li>봇, 매크로로 추정되는 참여자는 랜드NFT가 에어드랍되지 않고 전액 환불처리됩니다.</li>
        <li>랜드NFT 에어드랍과 환불에 대한 수수료는 클레이랜드 팀이 지불합니다.</li>
        <li><u>트랜잭션을 전송 후 3초안에 다시 트랜잭션을 보내면 무조건 실패합니다.</u></li>
      </ul>
      <a className={styles.readMore} target="_blank" href="https://bit.ly/KLMint3">
        프리 민팅(Pre-Minting) 자세히 알아보기
      </a>
    </div>
  );
});

export default Guide;