import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { PayloadAction } from '@reduxjs/toolkit';

export enum UtilsType {
  INITIALIZE = '@utils/INITIALIZE',
  SET_INITIALIZE = '@utils/SET_INITIALIZE',

  GET_BLOCK = '@utils/GET_BLOCK',
  GET_TIMESTAMP = '@utils/GET_TIMESTAMP',

  SET_BLOCK = '@utils/SET_BLOCK',
  SET_BLOCK_STATUS = '@utils/SET_BLOCK_STATUS',

  SET_TIMESTAMP = '@utils/SET_TIMESTAMP',
  SET_TIMESTAMP_STATUS = '@utils/SET_TIMESTAMP_STATUS',

  SET_PUBLIC_SALE_START_TIME = '@utils/SET_PUBLIC_SALE_START_TIME',
}

export interface UtilsState {
  publicSaleStartTime: number | null;
  currentSupply: number | null;
  totalSupply: number | null;
  price: string | null;

  blockTimestamp: number | null;
  blockDuration: number | null;
  blockStatus: 'top' | 'error';

  timestamp: number | null;
  timestampDuration: number | null;
  timestampStatus: 'top' | 'error';
}

export const UtilsActions = {
  initialize: createAction(UtilsType.INITIALIZE),
  setInitialize: createAction(UtilsType.SET_INITIALIZE),

  getBlock: createAction(UtilsType.GET_BLOCK),
  setBlock: createAction<[number, number]>(UtilsType.SET_BLOCK),
  setBlockStatus: createAction<'top' | 'error'>(UtilsType.SET_BLOCK_STATUS),

  getTimestamp: createAction(UtilsType.GET_TIMESTAMP),
  setTimestamp: createAction<[number, number]>(UtilsType.SET_TIMESTAMP),
  setTimestampStatus: createAction<'top' | 'error'>(UtilsType.SET_TIMESTAMP_STATUS),

  setPublicSaleStartTime: createAction<number>(UtilsType.SET_PUBLIC_SALE_START_TIME),
};

const initialState: UtilsState = {
  publicSaleStartTime: null,
  currentSupply: null,
  totalSupply: null,
  price: null,

  blockTimestamp: null,
  blockDuration: null,
  blockStatus: 'top',

  timestamp: null,
  timestampDuration: null,
  timestampStatus: 'top',
};

export default handleActions<UtilsState, any>(
  {
    [UtilsType.SET_PUBLIC_SALE_START_TIME]: (state, action: PayloadAction<number>) => {
      return produce(state, (draft) => {
        draft.publicSaleStartTime = action.payload;
      });
    },
    [UtilsType.SET_INITIALIZE]: (
      state,
      action: PayloadAction<{ publicSaleStartTime: number; currentSupply: number; totalSupply: number; price: string }>
    ) => {
      return produce(state, (draft) => {
        draft.publicSaleStartTime = action.payload.publicSaleStartTime;
        draft.currentSupply = action.payload.currentSupply;
        draft.totalSupply = action.payload.totalSupply;
        draft.price = action.payload.price;
      });
    },
    [UtilsType.SET_BLOCK]: (state, action: PayloadAction<[number, number]>) => {
      return produce(state, (draft) => {
        if (state.blockTimestamp === null || state.blockTimestamp <= action.payload[0]) {
          draft.blockTimestamp = action.payload[0];
          draft.blockDuration = action.payload[1];
          draft.blockStatus = 'top';
        }
      });
    },
    [UtilsType.SET_TIMESTAMP]: (state, action: PayloadAction<[number, number]>) => {
      return produce(state, (draft) => {
        if (state.timestamp === null || state.timestamp <= action.payload[0]) {
          draft.timestamp = action.payload[0];
          draft.timestampDuration = action.payload[1];
          draft.timestampStatus = 'top';
        }
      });
    },
    [UtilsType.SET_BLOCK_STATUS]: (state, action: PayloadAction<'top' | 'error'>) => {
      return produce(state, (draft) => {
        draft.blockStatus = action.payload;
      });
    },
    [UtilsType.SET_TIMESTAMP_STATUS]: (state, action: PayloadAction<'top' | 'error'>) => {
      return produce(state, (draft) => {
        draft.timestampStatus = action.payload;
      });
    },
  },
  initialState
);
