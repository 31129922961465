import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import './styles/common.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from "~/components/App/App";
import { Provider } from 'react-redux';
import { configureStore } from "~/cores/createStore";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { API_URI } from "~/cores/variables";
import axios from "axios";
import '~/styles/react-toastify.scss';

axios.defaults.baseURL = API_URI;

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={null}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
