import { put, delay, all, call, takeEvery } from 'redux-saga/effects';
import { UtilsActions, UtilsType } from '~/reducers/utils';
import { axiosInstance } from '~/cores/cores';
import { AxiosResponse } from 'axios';
import { NETWORK_NAME } from '~/cores/variables';

export default function* () {
  yield all([
    synchronizedInitialize(),
    synchronizedBlock(),
    synchronizedTimestamp(),

    watchInitialize(),
    watchGetBlock(),
    watchGetTimestamp(),
  ]);
}

function* synchronizedInitialize() {
  // while (true) {
    yield put(UtilsActions.initialize());
    // yield delay(10000);
  // }
}

function* synchronizedBlock() {
  // while (true) {
    yield put(UtilsActions.getBlock());
    // yield delay(1000);
  // }
}

function* synchronizedTimestamp() {
  // while (true) {
    yield put(UtilsActions.getTimestamp());
    // yield delay(1000);
  // }
}

function* watchInitialize() {
  yield takeEvery(UtilsType.INITIALIZE, function* () {
    try {
      const {
        data: {
          data: { price, publicSaleStartTime, currentSupply, totalSupply },
        },
      }: AxiosResponse<{
        data: { publicSaleStartTime: number; currentSupply: number; totalSupply: number; price: string };
      }> = yield call(() =>
        axiosInstance.post('/graphql', {
          query: `query($network: String) { publicSaleStartTime(network: $network) currentSupply(network: $network) totalSupply(network: $network) price(network: $network) }`,
          variables: { network: NETWORK_NAME },
        })
      );

      yield put(UtilsActions.setInitialize({ price, publicSaleStartTime, currentSupply, totalSupply }));
    } catch (e) {}
  });
}

function* watchGetBlock() {
  yield takeEvery(UtilsType.GET_BLOCK, function* () {
    const startTime = Date.now();

    try {
      const {
        data: {
          data: {
            block: { timestamp },
          },
        },
      }: AxiosResponse<{ data: { block: { timestamp: string } } }> = yield call(() =>
        axiosInstance.post('/graphql', {
          query: `query($network: String) { block(network: $network) { timestamp } }`,
          variables: { network: NETWORK_NAME },
        })
      );

      yield put(UtilsActions.setBlock([Number(timestamp), Date.now() - startTime]));
    } catch (e: any) {
      yield put(UtilsActions.setBlockStatus('error'));
    }
  });
}

function* watchGetTimestamp() {
  yield takeEvery(UtilsType.GET_TIMESTAMP, function* () {
    const startTime = Date.now();

    try {
      const {
        data: {
          data: { timestamp },
        },
      }: AxiosResponse<{ data: { timestamp: number } }> = yield call(() =>
        axiosInstance.post('/graphql', {
          query: `query { timestamp }`,
          variables: { network: NETWORK_NAME },
        })
      );

      yield put(UtilsActions.setTimestamp([timestamp, Date.now() - startTime]));
    } catch (e: any) {
      yield put(UtilsActions.setTimestampStatus('error'));
    }
  });
}
