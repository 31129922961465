import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';

export enum KaikasType {
  TRY_CONNECT = '@kaikas/TRY_CONNECT',
  TRY_DISCONNECT = '@kaikas/TRY_DISCONNECT',
  SET_CONNECTION_STATUS = '@kaikas/SET_CONNECTION_STATUS',
  SET_ADDRESS = '@kaikas/SET_ADDRESS',
}

export interface KaikasState {
  status: 'disconnected' | 'connecting' | 'connected';
  address: string | null;
}

export const KaikasActions = {
  tryConnect: createAction<void>(KaikasType.TRY_CONNECT),
  tryDisconnect: createAction<void>(KaikasType.TRY_DISCONNECT),
  setAddress: createAction<string>(KaikasType.SET_ADDRESS),
  setConnectionStatus: createAction<'disconnected' | 'connecting' | 'connected'>(KaikasType.SET_CONNECTION_STATUS),
};

const initialState: KaikasState = {
  status: 'disconnected',
  address: null,
};

export default handleActions<KaikasState, any>(
  {
    [KaikasType.SET_ADDRESS]: (state, action) => {
      return produce(state, draft => {
        draft.address = action.payload;
      })
    },
    [KaikasType.SET_CONNECTION_STATUS]: (state, action) => {
      return produce(state, draft => {
        draft.status = action.payload;
      })
    }
  },
  initialState
);