import { all, put, call, takeEvery } from 'redux-saga/effects';
import { KaikasActions, KaikasType } from "~/reducers/kaikas";
import { NETWORK_VERSION } from "~/cores/variables";

export default function* () {
  yield all([
    watchTryConnect(),
    watchTryDisconnect(),
  ]);
}

function* watchTryConnect() {
  yield takeEvery(KaikasType.TRY_CONNECT, function* () {
    if (typeof window.klaytn === 'undefined') {
      window.open('https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi')
      return;
    }

    if (window.klaytn.networkVersion !== NETWORK_VERSION) {
      alert('네트워크를 변경해주세요.');
      return;
    }

    yield put(KaikasActions.setConnectionStatus('connecting'));

    try {
      const [address]: Array<string> = yield call(window.klaytn.enable);
      yield put(KaikasActions.setConnectionStatus('connected'));
      yield put(KaikasActions.setAddress(address));
    } catch (e) {
      console.log(e)
      yield put(KaikasActions.setConnectionStatus('disconnected'));
    }
  });

}function* watchTryDisconnect() {
  yield takeEvery(KaikasType.TRY_DISCONNECT, function* () {
    if (typeof window.klaytn !== 'undefined') {

    }
  });
}