import Caver, { AbiItem, Contract } from "caver-js";
import abi from '../static/json/abi.json';
import { CONTRACT_ADDRESS, NETWORK_URI } from "~/cores/variables";

export function getSignedToolkit(): [Caver, Contract] | null {
  if (window.klaytn) {
    const caver = new Caver(window.klaytn as any);
    return [caver, caver.contract.create(abi as AbiItem[], CONTRACT_ADDRESS)];
  }

  return null;
}