import { combineReducers } from 'redux';
import utils, { UtilsState } from "~/reducers/utils";
import kaikas, { KaikasState } from "~/reducers/kaikas";

export default combineReducers<ApplicationStore>({
  utils,
  kaikas,
});

export interface ApplicationStore {
  utils: UtilsState;
  kaikas: KaikasState;
}