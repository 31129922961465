import React, { FC, memo } from 'react';
import styles from './Footer.module.scss';

export interface FooterProps {

}

const Footer: FC<FooterProps> = memo(() => {
  return (
    <div className={styles.footer}>
      <div className={styles.inner}>
        <div className={styles.copyright}>
          ⓒ 2021 KLAYLAND
        </div>
      </div>
    </div>
  );
});

export default Footer;