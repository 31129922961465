import React, { FC, memo } from 'react';
import styles from './NewHeader.module.scss';
import { Link } from 'react-router-dom';

export interface NewHeaderProps {

}

const NewHeader: FC<NewHeaderProps> = memo(() => {
  return (
    <div className={styles.newHeader}>
      <div className={styles.inner}>
        <div className={styles.head}>
          <Link to="/" className={styles.logo}>
            KLAYLAND
          </Link>
          <div className={styles.title}>
            KLAYLAND PREMINT
          </div>
        </div>
      </div>
    </div>
  );
});

export default NewHeader;